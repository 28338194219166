<template>
  <v-tabs
    class="tab-component"
    v-model="tab"
    center-active
    slider-size="4"
    ref="tab"
    show-arrows
  >
    <v-tabs-slider class="bg-blue-300"></v-tabs-slider>
    <v-tab
      v-for="tab in tabData"
      :key="tab.id"
      :style="{ width: tabWidth }"
      link
      :to="`/home/work-list/${tab.id}`"
    >
      <template>
        <v-icon
          @click.prevent="removeTab(tab.id)"
          v-if="![1, 2].includes(tab.id)"
          size="20"
          class="close"
          >mdi-close</v-icon
        >
        <v-badge
          color="pink"
          v-if="tab.countNew"
          :content="tab.countNew"
        ></v-badge>
        <div class="font-xxx-large total">
          {{ tab.total }}
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="font-x-large tab-title">
              {{ convertLangTab(tab.title) }}
            </div>
          </template>
          <span>{{ tab.title }}</span>
        </v-tooltip>
      </template>
    </v-tab>
  </v-tabs>
</template>

<script>
import WorkListService from "@/services/work-list";
import { awaitAll, getNumberFromString } from "@/plugins/helper";
const MIN_WIDTH_TAB = 267;
import i18n from "@/plugins/i18n";

export default {
  name: "TabComponent",
  data() {
    return {
      tab: null,
      tabWidth: MIN_WIDTH_TAB + "px",
    };
  },
  computed: {
    tabData() {
      return this.$store.getters.worklistTabs;
    },
  },
  mounted() {
    this.getTabWidth();
    this.getLatestTabTotals();
  },
  methods: {
    getTabWidth() {
      const tabWrapWidth = this.$refs.tab.$el.clientWidth;
      this.tabWidth =
        tabWrapWidth / Math.floor(tabWrapWidth / MIN_WIDTH_TAB) + "px";
    },
    // function to get all openned-tab newest totals
    async getLatestTabTotals() {
      // get list roomIDs
      var doctorID = this.$store.getters.userData.userID;
      var roomIDs = [];
      var tabs = JSON.parse(JSON.stringify(this.$store.getters.worklistTabs));
      for (var k = 2; k < tabs.length; k++) {
        roomIDs.push(getNumberFromString(tabs[k].id));
      }
      // create a list of promises
      var promises = [
        // count checkin tab
        WorkListService.SearchByDoctorID(doctorID, "", 1, "", "", 1, 0),
        // count booking tab
        WorkListService.SearchBookingByDoctorID(doctorID, "", 1, "", "", 1, 0),
      ];
      roomIDs.forEach((roomID) => {
        promises.push(
          WorkListService.SearchByRoomID(roomID, "", 1, "", "", 1, 0)
        );
      });
      // run them in paralells
      var result = await awaitAll(promises);
      // update total accordingly
      // Update total check-in
      this.$store.commit("UPDATE_WORKLIST_TAB_TOTAL", {
        id: 1,
        total: result[0].totals,
      });
      // Update total booking
      this.$store.commit("UPDATE_WORKLIST_TAB_TOTAL", {
        id: 2,
        total: result[1].totals,
      });
      // Update total room tabs
      for (var i = 0; i < roomIDs.length; i++) {
        var roomID = roomIDs[i];
        this.$store.commit("UPDATE_WORKLIST_TAB_TOTAL", {
          id: roomID,
          total: result[i + 2].totals,
        });
      }
    },
    removeTab(id) {
      // find the list of current tabs
      var tabs = JSON.parse(JSON.stringify(this.$store.getters.worklistTabs));
      // delete the selected tab
      this.$store.commit("REMOVE_WORKLIST_TAB", { id });
      // if user close the current tab
      if (id == this.$route.params.id) {
        // find the next tab to show
        var nextTabIDToDisplay = 1;
        for (var i = 0; i < tabs.length; i++) {
          if (tabs[i].id == id) {
            nextTabIDToDisplay = tabs[i - 1].id;
          }
        }
        this.$router.push(`/home/work-list/${nextTabIDToDisplay}`);
      }
    },
    convertLangTab(text) {
      var val = "";
      switch (text) {
        case "Check-In":
          val = i18n.t("main.workList.tab_head_checkIn");
          break;
        case "Booking":
          val = i18n.t("main.workList.tab_head_booking");
      }
      return val;
    },
  },
};
</script>

<style lang="scss">
.tab-component {
  .v-item-group {
    height: 84px;
    border-bottom: 1px solid #cacbcc;
  }

  .v-slide-group {
    &__prev {
      position: absolute;
      background-color: transparent;
      left: 0;
      top: 0;
      height: 84px;
      width: 52px;
      z-index: 100;

      &--disabled {
        display: none;
      }

      .v-icon {
        color: $blue-300;
        &::before {
          font-size: 42px;
        }
      }
    }

    &__next {
      position: absolute;
      right: 0;
      top: 0;
      height: 84px;
      width: 52px;
      z-index: 100;

      &--disabled {
        display: none;
      }

      .v-icon {
        color: $blue-300;
        &::before {
          font-size: 42px;
        }
      }
    }
  }

  .v-tab {
    min-width: 267px;
    height: 84px;
    flex-direction: column;
    // border-bottom: 1px solid #cacbcc;
    color: $neutral-30 !important;
    &--active {
      color: $blue-300 !important;
    }
    .total {
      line-height: 40px;
    }
    .tab-title {
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }
    .close {
      position: absolute;
      top: 5px;
      right: 20px;
      opacity: 0.7;
    }
  }
}
</style>
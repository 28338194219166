<template>
  <div class="work-list-page">
    <TabComponent />
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import TabComponent from "./TabComponent/Tab";

export default {
  name: "WorkListPage",
  components: {
    TabComponent,
  },
  data() {
    return {};
  },
};
</script>